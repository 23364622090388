import router from "next/router"
import { useProjects } from "@common/components/AppProjectsProvider"
import AppLoading from "@common/layouts/AppLoading"

const IndexPage = () => {
	const { projectsLoading, projectIdsByName } = useProjects()

	if (projectsLoading) {
		// Loading spinner will be surfaced below
	} else if (!projectIdsByName) {
		router.push(`/new`)
	} else if (projectIdsByName.length === 1) {
		router.push(`/p/${encodeURIComponent(projectIdsByName[0])}`)
		return null
	} else {
		router.push(`/projects`)
	}

	return <AppLoading />
}

export default IndexPage
